import {makeStyles} from '@material-ui/core';


export default makeStyles(() => ({
  root: {
    direction: "rtl",
  },
  card: {
    height: 285,
    width: 280,
    backgroundColor: "#b6b4b952",
    color: "#00004d"
  },
  cardHeader: {
    textDecoration: "underline"
  }
}));
