import React, { Component } from 'react'
import { Container, Header, Icon, Image, Table } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'

import Lecture1 from "../Pics/Lecture1.jpeg"
import Lecture2 from "../Pics/Lecture2.jpeg"

class Lectures extends Component {

  render() {

    return (
      <div>
      <Container dir="rtl">
        <center>
        <Header as="h1">הרצאות</Header>
        <Header as="h4">
 
בנוסף לשרותים המשפטיים הניתנים על ידנו, משרדנו מעביר גם הרצאות בתחום דיני עבודה, דיני הביטוח הלאומי, דיני משפחה, מקרקעין, תמ"א 38.
</Header>
<br/>
<Header as="h4">לתאום הרצאה ניתן להתקשר ל 03-5711797</Header>
<Link to='/צור קשר'  style={{ color: 'black', textDecoration: 'inherit' }}><Icon name='arrow down' size="big" ></Icon></Link>
<Link to='/צור קשר'  style={{ color: 'black', textDecoration: 'inherit' }}><u><Header as="h4">או לחץ כאן</Header></u></Link>
        </center>
      </Container>

<br/>
<br/>
<center>
<Table basic='very' collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell><Image src={Lecture1} size="large"></Image></Table.HeaderCell>
                <Table.HeaderCell><Image src={Lecture2} size="large"></Image></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          </Table>
          </center>
      </div>
    )
  }
}

export default withRouter(Lectures)