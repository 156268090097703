import React, { Component } from 'react'
import { Container, Header, Button, Card, Modal, Image } from 'semantic-ui-react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Article1 from '../Pics/Art1.pdf'
import Article2 from '../Pics/Art2.pdf'
import Article3 from '../Pics/Art3.pdf'
import Article4 from '../Pics/Art4.pdf'
import Article5 from '../Pics/Art5.pdf'

import pic from '../Pics/HomePic9.jpeg'

export default class Articles extends Component {

  render() {
    return (
      <div>
        <Popup
    // trigger={<button className="button"> Open Modal </button>}
    modal
    nested
    open
  >
    {close => (
      <div className="modal">
        <button className="close" onClick={close}>
          &times;
        </button>
        <center>
        <div className="header">  TheMarker - כתבה חדשה שלנו ב </div>
        <div className="content">
        עורכי דין לרכישת דירה: ליווי העסקה החשובה בחיים
        <br/>
        <a href="https://www.themarker.com/labels/1.9889866#anc_1"><Modal trigger={<Button basic color='green'>
                קרא את הכתבה
          </Button>}>
                <embed src={Article1} width="100%" height="1100px" />
              </Modal></a>
        </div>
        </center>
      </div>
    )}
  </Popup>
      <Container dir="rtl">
        <Header as="h1"><u>עו"ד רונית גוטשל מפרסמת מאמרים וסקירות פסיקה בכתבי עת שונים.</u>

</Header>
<br/>
        <Card.Group>
          <Card>
            <Card.Content>

              <Card.Header>עורכי דין לרכישת דירה: ליווי העסקה החשובה בחיים
</Card.Header>
             

            </Card.Content>
            <Card.Content extra>
              
              <Modal trigger={<Button basic color='green'>
                קרא את הכתבה
          </Button>}>
          <embed src={Article5} width="100%" height="1100px" />
              </Modal>
          <a href="https://www.themarker.com/labels/1.9889866#anc_1">לחץ לכניסה לכתבה</a>
          

            </Card.Content>
          </Card>
          <Card>
            <Card.Content>

              <Card.Header>כך האב זכה במשמורת משותפת
</Card.Header>
             

            </Card.Content>
            <Card.Content extra>
              <Modal trigger={<Button basic color='green'>
                קרא את הכתבה
          </Button>}>
                <embed src={Article1} width="100%" height="1100px" />
              </Modal>

            </Card.Content>
          </Card>
          <Card>
            <Card.Content>

              <Card.Header>כל מה שרציתם לדעת על דמי הבראה
</Card.Header>
              

            </Card.Content>
            <Card.Content extra>
              <Modal trigger={<Button basic color='green'>
                קרא את הכתבה
          </Button>}>
                <embed src={Article2} width="100%" height="1100px" />
              </Modal>

            </Card.Content>
          </Card>
          <Card>
            <Card.Content>

              <Card.Header>נדל"ן בחו"ל: הטעיה או עסקה כשרה?
</Card.Header>

            </Card.Content>
            <Card.Content extra>
              <Modal trigger={<Button basic color='green'>
                קרא את הכתבה
          </Button>}>
                <embed src={Article3} width="100%" height="1100px" />
              </Modal>

            </Card.Content>
          </Card>
          <Card>
            <Card.Content>

              <Card.Header>הדירה התבררה פתאום כמחסן
</Card.Header>
            </Card.Content>
            <Card.Content extra>
              <Modal trigger={<Button basic color='green'>
                קרא את הכתבה
          </Button>}>
                <embed src={Article4} width="100%" height="1100px" />
              </Modal>

            </Card.Content>
          </Card>
        </Card.Group>
        <br/>
        <br/>
        <br/>
        <br/>
        <center>
        <Image src={pic} size="medium"></Image>
        </center>
      </Container>
      </div>
    )
  }
}
