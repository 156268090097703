import React, { Component } from 'react'
import { Container, Image, Item, List, Header } from 'semantic-ui-react'
import AdiPic from '../Pics/AdiPic.png'
import RonitPic from '../Pics/RonitPic.png'

export default class Staff extends Component {

  render() {

    return (
      <Container dir="rtl">
      <br />
      <br />
        <Item.Group dir="ltr">
          <Item>
            <Image src={RonitPic} wrapped ui={false} className="staffPic"/>
            
            <Item.Content className="letsTry" dir="rtl">
              <b><Item.Header as="h1">     גוטשל רונית, עורכת דין      </Item.Header></b>
              <Item.Description as="h3"  className="Description">
                <Header>
                  עו"ד עצמאית, חברה בלשכת עורכי הדין משנת 1997, מקימת המשרד, בעלת ניסיון מעשי עשיר בתחום האזרחי, מומחית בתחום המקרקעין בדיני עבודה ובדיני משפחה. יצוגית, בעלת כושר שכנוע ויכולת ניהול מו"מ בכל הרמות. התנסחות בכתב ובע"פ ברמה גבוה.
                  </Header>
                <List>
                  <List.Item>בעלת תואר ראשון  B.Aבמדעי המדינה.</List.Item>
                  <List.Item>תואר L.L.B.  במשפטים.</List.Item>
                  <List.Item>לימודי כלכלה במסגרת התואר הראשון.</List.Item>
                  <List.Item>לימודי התנהגות ארגונית במסגרת תואר שני.</List.Item>
                  <List.Item>בוגרת מסלול ניהול של הבנק הבינ"ל הראשון.</List.Item>
                  <List.Item>התמחות בביה"ד האזורי לעבודה בתל אביב.</List.Item>
                  <List.Item>מגשרת מוסמכת על ידי המרכז הישראלי לגישור ואימון גומא.</List.Item>
                </List>

              </Item.Description>
            </Item.Content>
          </Item>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Item>
            <Image src={AdiPic} wrapped ui={false} className="staffPic"/>

            <Item.Content className="letsTry"  dir="rtl">
              <b><Item.Header as="h1">גוטשל עדי, עורך דין
</Item.Header></b>
              <Item.Description as="h3" className="Description">
                <p>
                  עו"ד עצמאי, הצטרף למשרד בשנת 2010 . בעל ניסיון מעשי עשיר בתחום האזרחי, דיני עבודה ודיני חברות כמעסיק. מומחה בדיני משפחה. ניסיון בניהול מתקדם רב תחומי של תהליכים משפטיים מקביליים, מורכבים ורגישים. יצוגי, בעל כושר שכנוע ויכולת ניהול מו"מ בכל הרמות. התנסחות בכתב ובע"פ ברמה גבוה.
                </p>

                <List>
                  <List.Item>   טייס מסוקים לשעבר בחיל האוויר.</List.Item>
                  <List.Item>בעל תואר ראשון B.Sc  בהצטיינות בהנדסת תעשיה וניהול.</List.Item>
                  <List.Item>בעל תואר שני במנהל עסקים.</List.Item>
                  <List.Item>בעל תואר L.L.B במשפטים</List.Item>
                  <List.Item>לשעבר מנכ"ל וסמנכ"ל בחברות היי-טק לרבות אחריות על כל הפן המשפטי של החברות.</List.Item>
                </List>
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Container>
    )
  }
}
