import {makeStyles} from '@material-ui/core';


export default makeStyles(() => ({
  root: {
    direction: "rtl",
    backgroundColor: "#00004d",
    color: 'white',
    content: 'width=device-width'
  },
 navbar: {
    display: "inline-flex",
 },
 logo: {
    marginInlineStart: "auto",
 },
 subList: {
    direction: "rtl",
    backgroundColor: "#00004d",
     width: 130,
 },
 link: {
    color: 'white',
    textAlign: 'center'
 },
 subMenuItem: {
     textAlign: "center"
 }
}));
