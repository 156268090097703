import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import Navbar from './components/Navbar/Navbar'
import Articles from './components/Articles'
import ContactUs from './components/ContactUs'
import Staff from './components/Staff'
import Law from './components/LawPage'
import Family from './components/FamilyPage'
import Realestate from './components/RealestatePage'
import AboutUs from './components/AboutUs/AboutUs'
import Lectures from './components/Lectures'
import Footer from './components/Footer/Footer'
import Recommendation from './components/recommendations'
import { Container } from '@material-ui/core';
import { WORK_URL, FAMILY_URL, GROUND_URL, ABOUT_URL, CONTACT_URL, LECTURES_URL, TEAM_URL, STORIES_URL, RECOMMENDATION_URL } from "./constants/Urls";

export default class App extends Component {

  
  render() {
    return (
      <div style={{ backgroundColor: "#eeeeee" }} >
        <BrowserRouter>
          <Navbar />
          <Container fixed maxWidth="lg" style={{ padding: 30 }}>
            <Route exact path={ABOUT_URL} component={AboutUs}></Route>
            <Route path={STORIES_URL} component={Articles}></Route>
            <Route path={CONTACT_URL} component={ContactUs}></Route>
            <Route path={TEAM_URL} component={Staff}></Route>
            <Route path={WORK_URL} component={Law}></Route>
            <Route path={GROUND_URL} component={Realestate}></Route>
            <Route path={FAMILY_URL} component={Family}></Route>
            <Route path={LECTURES_URL} component={Lectures}></Route>
            <Route path={RECOMMENDATION_URL} component={Recommendation}></Route>
          </Container>

          <Footer />
        </BrowserRouter>
      </div>
    )
  }
}