import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Paper, List, ListItem, ListItemText, MenuList, MenuItem, Popper, ListItemIcon, Typography} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import {WORK_URL, FAMILY_URL, GROUND_URL, ABOUT_URL, CONTACT_URL, LECTURES_URL, TEAM_URL, STORIES_URL, RECOMMENDATION_URL } from "../../constants/Urls";
import Logo2 from "../../Pics/Logo2.png"
import useStyle from './Navbar.css'

const Navbar = (props) => {
    const [selctedPage, setSelectedPage] = useState(0);  
    const [openList, setOpenList] = useState(false);
    const classes = useStyle();
    const anchorRef = useRef(null);

    const handleListItemClick = (event, newValue) => {
        setSelectedPage(newValue);
    };
    
    return (
        <>
        <Grid container className={classes.root} alignItems="center">
            <Grid item>
                <MenuList className={classes.navbar}>                         
                    <Link to={ABOUT_URL} className={classes.link}>
                        <MenuItem
                            selected={selctedPage === 0}
                            onClick={(event) => handleListItemClick(event, 0)}
                        >                    
                            <ListItemText primary="אודותינו" />                    
                        </MenuItem>                        
                    </Link>     
                    <Link to={TEAM_URL} className={classes.link}>
                        <MenuItem
                            selected={selctedPage === 1}
                            onClick={(event) => handleListItemClick(event, 1)}
                        >                    
                            <ListItemText primary='צוות המשרד'/>                    
                        </MenuItem>                        
                    </Link>
                    <MenuItem
                        className={classes.subList}
                        selected={selctedPage === 2}
                        onClick={(event) => handleListItemClick(event, 2)}
                        onMouseOver={()=> {setOpenList(true)}}
                        onMouseLeave={() => {setOpenList(false)}}
                        innerRef={anchorRef}
                    >
                        <Typography>תחומי התמחות</Typography>
                        <ExpandMore />
                      <Popper open={openList} anchorEl={anchorRef.current} placement='bottom-start'>
                          <Paper className={classes.subList}>
                              <MenuList >
                                <Link to={WORK_URL} className={classes.link}>
                                    <MenuItem className={classes.subMenuItem}>                                    
                                        <ListItemText primary="דיני עבודה" />                                    
                                    </MenuItem>
                                </Link>
                                <Link to={GROUND_URL} className={classes.link}>
                                    <MenuItem className={classes.subMenuItem}>                                    
                                        <ListItemText primary="דיני מקרקעין" />                                    
                                    </MenuItem>
                                </Link>
                                <Link to={FAMILY_URL} className={classes.link}>
                                    <MenuItem className={classes.subMenuItem}>                                    
                                        <ListItemText primary="דיני משפחה" />                                    
                                    </MenuItem>
                                </Link>
                            </MenuList>
                          </Paper>      
                      </Popper>
                    </MenuItem>
                     <Link to={STORIES_URL} className={classes.link}>
                        <MenuItem
                            selected={selctedPage === 3}
                            onClick={(event) => handleListItemClick(event, 3)}
                        >                    
                            <ListItemText primary="כתבות" />
                        </MenuItem>
                    </Link>                        
                    <Link to={LECTURES_URL} className={classes.link}>
                        <MenuItem
                            selected={selctedPage === 4}
                            onClick={(event) => handleListItemClick(event, 4)}
                        >                    
                            <ListItemText primary="הרצאות" />                            
                        </MenuItem>
                    </Link>                        
                    <Link to={CONTACT_URL} className={classes.link}>
                        <MenuItem
                            selected={selctedPage === 5}
                            onClick={(event) => handleListItemClick(event, 5)}
                        >                    
                            <ListItemText primary="צור קשר" />                    
                        </MenuItem>                      
                    </Link>
                    <Link to={RECOMMENDATION_URL} className={classes.link}>
                        <MenuItem
                            selected={selctedPage === 0}
                            onClick={(event) => handleListItemClick(event, 0)}
                        >                    
                            <ListItemText primary="לקוחות מודים" />                    
                        </MenuItem>                        
                    </Link>     
                </MenuList>
            </Grid>
            <Grid item className={classes.logo}>
                <Link to={ABOUT_URL}>
                    <img src={Logo2} height={75} alt=""/>
                </Link>         
            </Grid>
        </Grid>
        </>        
    );
}

export default Navbar;