import React, { Component } from 'react'
import { Container, Header, List, Image } from 'semantic-ui-react'

import pic from "../Pics/HomePic4.jpg"

export default class ContactUs extends Component {

  render() {

    return (
      <div dir="rtl" className="PageOfWords">
        <center>
          <Image src={pic} size="big"></Image>
        </center>
        <Container fluid>
          <u> <b> <Header as="h2">חוזה רכישת נכס / חוזה מכירת נכס</Header></b></u>
          <Header as="h4">עסקת מקרקעין היא בין העסקאות הגדולות והמורכבות, שאדם מבצע במהלך חייו. כאשר אתם מעורבים בעסקה בשווי כה גבוה חשוב מאוד שתקבלו את הייעוץ והליווי המקצועי שיבדוק את הפן המשפטי של העסקה וידאג להגן עליכם ועל זכויותיכם בנכס שאתם רוכשים או מוכרים.</Header>
          <Header as="h4">לעו"ד גוטשל ניסיון מעשי עשיר, בייצוג מאות רוכשים ומוכרים בעסקאות נדל"ן מגוונות בכל רחבי הארץ.
          בעוד אתם מתמקדים ,מטבע הדברים, בצד המסחרי של העסקה, אנו נבדוק את העסקה מהפן המשפטי נטפל נבדוק ונוודא שמלוא זכויותיכם מוגנות ונטפל בכל תהליכי האישור והרישום הנדרשים.
</Header>
          <Header as="h4">כמשרד המתמחה בתחום המקרקעין אנו עובדים רבות מול רשויות המיסים, התכנון והרישוי הנדרשים בעסקאות השונות. ייצוג בועדות ערר, ועדות מחוזיות, הופעות בבימ"ש בערכאות שונות לרבות ייצוג בתביעות מול מינהל מקרקעי ישראל, ייצוג מול רשויות, עיריות ורשויות מקומיות בכל הקשור לחריגות בנייה, צווי הריסה, עיקולים, שינויי ייעדי וצווי הפסקת בניה. יעוץ משפטי בתחום התכנוני לקבלנים יזמים ובעלי נכסים.</Header>
          <br />





          <u> <b> <Header as="h2">תמ"א 38 / פינוי בינוי</Header></b></u>

          <Header as="h4">כחלק מההתחדשות העירונית תוכנית תמ"א 38 היא הנפוצה והמוכרת ביותר לקהל הרחב בשנים האחרונות. תמ"א 38 היא תוכנית ארצית לשיפוץ וחיזוק מבנים הכוללת הוספת דירות כנגד שיפוץ המבנה הקיים (עיבוי) ולחילופין מאפשרת התוכנית הריסת המבנה הקיים ובנית מבנה חדש וגדול יותר תחתיו (תמ"א 38 סעיף 2). במתחמים גדולים עם מספר רב של דירות ניתן להכריז על הפרוייקט כ "פרוייקט פינוי בינוי" אשר במסגרתו יוכל היזם לקבל הקלות והטבות נוספות בזכויות הבניה.
                    </Header>
          <Header as="h3">תוכניות התמ"א 38 ותוכניות הפינוי בינוי מטיבות עם הדיירים שמקבלים מהיזם שידרוג משמעותי של הנכס שבבעלותם:</Header>
          <List>
            <List.Item><Header as="h3">•	בתמ"א 38 -  בדמות חיזוק המבנה, תוספת ממ"דים, מעליות, מרפסות, שיפוץ הלובי שיפוץ החזית ועוד.</Header></List.Item>
            <List.Item><Header as="h3">•	בתמ"א 38 (2) ובפינוי בינוי – דירה/נכס חדש גדול יותר בבניין חדש עם חניה, מעלית, מרפסת שמש, ממ"ד ועוד.</Header></List.Item>
          </List>
          <br />


          <Header as="h4">מנגד מתעוררות סוגיות משפטיות רבות שיש לשים אליהן לב ולהגן על בעלי הדירות הקיימות.
          אנו מתמחים במציאת שביל הזהב שיאפשר ליזם להוציא לפועל את הפרוייקט אך ימנע פגיעה אפשרית בבעלי הדירות. אנו נדאג להגן על זכויותיכם. נבדוק את הערבויות, הביטוח, המועדים והתחיבויות היזם והקבלן ונוודא שאתם זוכים להגנה המשפטית הטובה ביותר, מגובים בערבויות מתאימות כנדרש, נחזיק את ייפויי הכח בשמכם, נעקוב ונהיה לצדכם במהלך הפרויקט.
</Header>

          <Header as="h4">משרדנו פועל כבר מספר שנים בתחום ההתחדשות העירונית ומייצג יזמים ודיירים רבים בעשרות פרויקטים של עסקעות תמ"א 38 לרבות פרוייקטי תמ"א 38 עיבוי, תמ"א 38 (2) הריסה ובינוי, פרויקטים של פינוי בינוי ועסקאות קומבינציה.
          המשרד מטפל בפרויקטים בתל אביב, יפו, רמת גן, גבעתיים, חולון ועוד.
</Header>

        </Container>
      </div>
    )
  }
}
