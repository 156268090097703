import React from 'react';
import { Grid, List, ListItem, Typography } from '@material-ui/core';
import { Icon } from 'semantic-ui-react'
import useStyle from './Footer.css';
import Logo from "../../Pics/Logo.png"
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
    map: {
        height: 178,
        width: 403,
    },
    root: {
        height: 178,
        width: 403,
    }
};

const mapLocation = { lat: 32.0543942, lng: 34.8493041 };

const Footer = ({ google }) => {
    const classes = useStyle();

    return (
        <>
            <Grid container className={classes.root} alignItems="center">
                <Grid item>
                    <img src={Logo} height={75} alt="" />
                </Grid>
                <Grid item>
                    <List>
                        <ListItem >
                            <Icon name='phone' /><Typography>טלפון: <a href="tel:035711797">03-5711797</a></Typography>
                        </ListItem>
                        <ListItem>
                            <Icon name='fax' /><Typography>פקס:<a> 03-5711798</a></Typography>
                        </ListItem>
                        <ListItem>
                            <Icon name='at' /><Typography>דואר אלקטרוני: <a href="mailto:gotshal.law@gmail.com">gotshal.law@gmail.com</a></Typography>
                        </ListItem>
                        <ListItem>
                            <Icon name='map marker alternate' /><Typography>כתובתנו: <a href="https://www.waze.com/he/livemap/directions?latlng=32.0543493%2C34.8490862&navigate=yes&utm_expid=.eESptsYIRfOj3UqE-qP26w.0&utm_referrer=">האחים ליטוינסקי 10, רמת גן</a></Typography>
                        </ListItem>
                    </List>
                </Grid>

                <Grid item style={{ height: 129, width: 338 }}>
                    <Map
                        google={google}
                        zoom={17}
                        initialCenter={mapLocation}
                        style={mapStyles}
                        containerStyle={{ height: 130, width: 320 }}

                    >
                        <Marker position={mapLocation} />
                    </Map>
                </Grid>
            </Grid>
        </>
        // <div>

    )
}


export default GoogleApiWrapper({
    apiKey: 'AIzaSyCWAnRky51ajfzCZmf2vDOQAaKr6u8OPas',
    language: 'heb'
})(Footer);