import React from 'react'
import { Grid, Card, CardContent, CardHeader, Typography, CardActions, Button } from "@material-ui/core";
import { WORK_URL, FAMILY_URL, GROUND_URL } from "../../constants/Urls";
import useStyle from './AboutUs.css'
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom'
import SwipeableTextMobileStepper from '../Rotating_pics'

const AboutUs = (props) => {
  const classes = useStyle();

  return (
    <div className={classes.root}>

      <SwipeableTextMobileStepper />
      <Typography paragraph variant='h6'>משרד עו"ד דין גוטשל הינו משרד משפחתי הפועל משנת 1998 עם ניסיון והתמחות במגוון תחומים. המשרד מאופיין כמשרד בוטיק בו כל לקוח מקבל יחס אישי וליווי צמוד בכל התהליכים המשפטיים. בכל רגע נתון אנו פועלים כשטובת הלקוח לנגד עינינו. הידע המשפטי הנרחב, הניסיון המקצועי העשיר ותודעת השירות הגבוהה, הם סימני ההיכר של המשרד, אשר הפכו אותנו למשרד מוביל בתחומנו.</Typography>

      <Typography variant="h4" paragraph>
        תחומי ההתמחות שלנו:
    </Typography>
      <Grid container spacing={3} justify="center">
        <Grid item>
          <Card className={classes.card} elevation={4}>
            <CardHeader title="דיני עבודה" className={classes.cardHeader} />
            <CardContent>
              <Typography>
                * הסכמי עבודה לעובדים, קבלנים ונותני שירות
              </Typography>
              <Typography>
                * פיטורין/התפטרות
              </Typography>
              <Typography>
                * הטרדות מיניות
              </Typography>
              <Typography>
                * ביטוח לאומי
              </Typography>
            </CardContent>

            <CardActions disableSpacing>
              <Link to={WORK_URL}>
                <Button color="primary">קרא עוד</Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.card} elevation={4}>
            <CardHeader title="דיני מקרקעין" className={classes.cardHeader} />
            <CardContent>
              <Typography>
                * חוזה רכישת נכס / חוזה מכירת נכס
              </Typography>

              <Typography>
                * תמ"א 38 / פינוי בינוי
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <Link to={GROUND_URL}>
                <Button color="primary">קרא עוד</Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.card} elevation={4}>
            <CardHeader title="דיני משפחה" className={classes.cardHeader} />
            <CardContent>
              <Typography>
                * גירושין
              </Typography>

              <Typography>
                * באמצעות גישור (עוה"ד רונית גוטשל הנה  מגשרת  מוסמכת)
              </Typography>

              <Typography>
                * בערכאות המשפטיות
              </Typography>

              <Typography>
                * צוואות
              </Typography>

              <Typography>
                * הסכמי ממון
              </Typography>

              <Typography>
                * ייפוי כח מתמשך
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <Link to={FAMILY_URL}>
                <Button color="primary">קרא עוד</Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>

      </Grid>
    </div>
  );
}

export default withRouter(AboutUs);

