import React, { useState } from 'react'
import { Container, Pagination, Header, Image } from 'semantic-ui-react'

import recommendation1 from '../Pics/recommendation1.png'
import recommendation2 from '../Pics/recommendation2.jpg'
import recommendation4 from '../Pics/recommendation4.jpg'
import recommendation5 from '../Pics/recommendation5.jpeg'
import recommendation6 from '../Pics/recommendation6.jpg'
import recommendation7 from '../Pics/recommendation7.png'
import recommendation8 from '../Pics/recommendation8.jpg'



// const useStyles = makeStyles((theme) => ({
//     img: {
//         height: 255,
//         display: 'block',
//         maxWidth: 500,
//         overflow: 'hidden',
//         width: '100%',
//     },
// }));

export default function Recommendation() {

    const [pics, setPics] = useState([recommendation1, recommendation2, recommendation4, recommendation5, recommendation6, recommendation7, recommendation8])
    const [page, setPage] = useState(1)

    return (
        <Container dir="rtl">
            <center>
                <Header as="h1"><u>לקוחות מודים ומברכים</u></Header>
                <Image src={pics[page - 1]} style={{height: 600,
        display: 'block',
        maxWidth: 700,
        overflow: 'hidden',
        width: '100%'}} />
                <Pagination defaultActivePage={1} totalPages={pics.length}
                    onPageChange={(event, data) => setPage(data.activePage)}
                    activePage={page}
                />
            </center>
        </Container>
    )

}
