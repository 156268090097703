import React, { Component } from 'react'
import { Container, Header, Image } from 'semantic-ui-react'

import pic from "../Pics/HomePic10.jfif"

export default class ContactUs extends Component {

  render() {

    return (
      <div dir="rtl" className="PageOfWords">
        <center>
          <Image src={pic} size="large"></Image>
        </center>
        <Container fluid>
          <u> <b> <Header as="h2">גירושין</Header></b></u>
          <Header as="h4">כאשר אחד או שני בני הזוג מחליטים להתגרש ולפרק את התא המשפחתי מייד מתעוררות הסוגיות המשפטיות של משמורת ילדים, הסדרי ראייה, מזונות, חלוקת רכוש, פירוק שיתוף וגירושין (גירושין ברבנות ומתן גט).</Header>
          <br />





          <u> <b> <Header as="h2">באמצעות גישור (עוה"ד רונית גוטשל הנה מגשרת מוסמכת)</Header></b></u>

          <Header as="h4">במידה וקיימת הבנה והדברות בין הצדדים אנו מלווים את בני הזוג בתהליך הדברות תוך כדי גישור בסיוע ייעוץ משפטי מקצועי, במטרה להגיע בזמן קצר להסכם גירושין מפורט המסדיר בהסכמה ובהבנה את כל הסוגיות המשפטיות והאופרטיביות לרבות משמורת ילדים, מזונות וחלוקת רכוש. הסכם זה יוגש עלידנו לבית המשפט ויקבל תוקף פסק דין כך שהגירושין יוכלו להתבצע בזמן הקצר ביותר תוך חיסכון בעלויות והקטנת הנזק והמטען הרגשי.
                    </Header>
          <Header as="h3">עו"ד גוטשל בעלי ניסיון עשיר בייצוג עובדים וניהול מלחמתם בכל הערכאות המשפטיות.</Header>

          <br />




          <u> <b> <Header as="h2">בערכאות המשפטיות</Header></b></u>
          <Header as="h4">לחילופין, במקרים רבים בני הזוג לא מצליחים להגיע להבנה ו/או אינם מתקשרים ולכן יש צורך לערב את בית הדין הרבני או בית המשפט לעינייני משפחה בכדי שיפסוק בסוגיות שבמחלוקת. במקרים אלו, אנו כעורכי דין המתמחים בדיני משפחה, מייצגים אותך ונלחמים בשבילך בכל הערכאות בבתי הדין הרבניים ובבתי המשפט לעינייני משפחה ככל שנידרש. ביודענו שתהליך הגירושין ביסודו הינו תהליך מורכב וטעון ריגשית, אנו מכירים את המועקות והצרכים שלך ויודעים להקדים תרופה למכה. כמשרד בוטיק אנו נותנים לך ליווי אישי צמוד בכל הנושאים וביחד צולחים את המהמורות שנפערו ו/או שיפערו במעלה הדרך. אנו יודעים שזה הזמן הרגיש ביותר והפגיע ביותר שלך. באמצעות המקצועיות והמומחיות שלנו נעמוד על זכויותיך ונוביל אותך לחוף מבטחים למרות הסערה המתחוללת בחוץ.</Header>

          <u> <b> <Header as="h2">צוואות</Header></b></u>
          <Header as="h4">משרדנו מטפל בהכנת צוואות, הוצאת צווי קיום צוואה ו/או צווי ירושה.
          מייצג בהליכים ובערכאות השונות במקרים של התנגדות לקיום צוואה ו/או מחלוקת בגין צוואה או ירושה.</Header>

          <u> <b> <Header as="h2">הסכמי ממון</Header></b></u>
          <Header as="h4">בזכות הידע הרב שצברנו בתחום, אנו ממליצים בחום לערוך מבעוד מועד הסכמי ממון. הסכמי הממון המפורטים שאנו עורכים נועדו להעלות על הכתב את ההסכמות ובכך למנוע בעיות ואי נעימויות גדולות בעתיד.</Header>

          <u> <b> <Header as="h3">ייצוג במחלוקות משפחתיות, לרבות פירוק שיתוף בין בני משפחה.</Header></b></u>

          <br />

          <u> <b> <Header as="h2">ייפוי כח מתמשך</Header></b></u>
          <Header as="h4">עורכת הדין רונית גוטשל בעלת הסמכה ורישיון לייפוי כח מתמשך.</Header>


        </Container>
      </div>
    )
  }
}
