import React, { Component } from 'react'
import { Container, Header, Card } from 'semantic-ui-react'

export default class ContactUs extends Component {

  render() {

    return (
      <Container dir="rtl">
        <center>
        <Header as="h1">צרו איתנו קשר!</Header>
        </center>
        <br/>
        <Card.Group>
          <Card fluid color='red' header='כתובת:  רחוב האחים ליטוינסקי 10, רמת גן' />
          <Card fluid color='orange' header='מיקוד:    5262152' />
          <Card fluid color='yellow' header='טלפון:    03-5711797' />
          <Card fluid color='yellow' header='פקס:      03-5711798' />
          <Card fluid color='yellow' header='דואר אלקטרוני: gotshal.law@gmail.com' />
        </Card.Group>
      </Container>
    )
  }
}
