import React, { Component } from 'react'
import { Container, Header, Image, Grid } from 'semantic-ui-react'

import pic from "../Pics/HomePic7.jpeg"

export default class ContactUs extends Component {

    render() {

        return (
            <div dir="rtl" className="PageOfWords">
                <Grid>
    <Grid.Row>
      <Grid.Column width={13}>
      <br />
      <br />
      <br />
      <br />

      <u> <b> <Header as="h2">                   הסכמי עבודה לעובדים, קבלנים ונותני שירות</Header></b></u>
                    <Header as="h4">     כעורכי דין בתחום דיני העבודה אנו מייצגים עובדים ומעסיקים עוד בשלב הכנת הסכמי עבודה, החתמת עובדים שעתיים וגלובליים על הסכמי עבודה מפורטים, בקרה והדרכה של דיווחי שכר בהתאם לדרישות החוק, הסכמי העסקה של קבלנים ונותני שירות ומתן ייעוץ שותף בכל השאלות הנובעות מיחסי עבודה.</Header>
                    <br />
      </Grid.Column>
      <Grid.Column width={3}>
        <Image src={pic} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
                <Container fluid>
                    <u> <b> <Header as="h2">  פיטורין/התפטרות</Header></b></u>

                    <Header as="h4">אם פוטרת מהעבודה ו/או את/ה חושב/ת שעומדים לפטר אותך מהעבודה או שאת/ה רוצה להתפטר מעבודתך, זה הזמן להתייעץ עם עורכי דין מנוסים בתחום דיני העבודה, במועד סיום יחסי עובד מעביד עולות הסוגיות המשפטיות של שימוע, מתן הודעה מוקדמת, עבודה בתקופת ההודעה המוקדמת ו/או חלף הודעה מוקדמת.

                    התפטרות אל מול פיטורין, פיטורי עובדת בהריון, הרעת תנאים והתפטרות בחזקת מפוטר.

                    תשלום בגין ימי מחלה, חופשה, הבראה, פנסיה וגמל, שכר שווה לעובדים, פיטורין בגין חשיפת מעשי שחיתות, שוויון הזדמנויות ועוד.

                    יעוץ מקצועי ובעקבותיו התנהלות נכונה בתקופה זו, יכולות להשפיע באופן משמעותי על מימוש זכויותכם המלאות.

                    </Header>
                    <Header as="h3">עו"ד גוטשל בעלי ניסיון עשיר בייצוג עובדים וניהול מלחמתם בכל הערכאות המשפטיות.</Header>

                    <br />




                    <u> <b> <Header as="h2">הטרדות מיניות</Header></b></u>
                    <Header as="h4">בתי הדין לעבודה נותנים מענה להטרדות מיניות המבוצעות במסגרת יחסי עובד מעביד תוך כדי ניצול לרעה של יחסי מרות. משרדנו מתמחה בייצוג בתביעות ו/או בתלונות בגין הטרה מינית (ייצוג המתלונן/ת ו/או ייצוג המטריד לכאורה) .</Header>



                    <u> <b> <Header as="h2">                    ביטוח לאומי</Header></b></u>

                    <Header as="h4">                    ברבות השנים והניסיון הרב שמשרדנו צבר, אנו מתמחים בתחום התביעות כנגד המוסד לביטוח לאומי:

                    תביעות נכות מעבודה -  הן בשל תאונות עבודה והן בשל אופי העבודה/ מחלת מקצוע .אנו עוזרים למלא ויחד מגישים את התביעה, מפנים  למומחים עימם אנו עובדים לקבלת חוו"ד רפואית מתאימות, מלווים לוועדות הרפואיות השונות ובמקרה הצורך מגישים ערר לוועדות ו/או לביה"ד לעבודה.

                    קביעת נכות זמנית וקבועה ובהמשך אף תביעות בגין החמרה והגדלת אחוזי הנכות.

                    בנוסף, אנו עוסקים ומייצגים בכל שאר התביעות כנגד המל"ל, כגון: תביעה לדמי אבטלה, דמי לידה ו/או שמירת הריון.



                    מתן מענה לעובדים בהליך של פשיטת רגל של המעסיק לרבות בהגשת תביעה לקבלת תשלומים מהמל"ל (במקום המעביד) .</Header>
                </Container>
            </div>
        )
    }
}
